import Q from 'q';
import _ from 'underscore';
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_t_od_hotel_order_interim_hotelOrderPolicyTips from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelOrderPolicyTips' // 酒店预定页-个人差标提示
import consumer_department_getStaffsByEvectionNo from '@/lib/data-service/haolv-default/consumer_department_getStaffsByEvectionNo' // 根据出差单号返回关联的出行人员
import consumer_admin_journey_calculateHotelPrice
    from '@/lib/data-service/haolv-default/consumer_admin_journey_calculateHotelPrice'//计算总价
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_t_od_hotel_order_interim_createOrderByOnBusiness
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_createOrderByOnBusiness' //创建订单
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_journey_orderFreeApprove from "@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove.js";
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0'

import BackBtn from '@/component/backBtn/index.vue'//返回按钮
import EvectionForm from '@/component/evectionForm/import_latest_version_module'   // 因公出行信息显示组件
import TravelerMultipleSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';//选人列表组件
import BaseMultipleEntitySelector0301
    from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/base-multiple-entity-selector-0301/1.0.0/index.vue';//在出差单出行人中选择入住人列表组件
import ReserveProcessBox from '@/component/reserve-process-box/1.0.0/index.vue'
import consumer_payment_payReminder from '@/lib/data-service/haolv-default/consumer_payment_payReminder'
import moment from "moment";

export default {
    data() {
        return {
            loading: false,
            hasNodeList: false,
            isShowStandard: true,
            isFreeApprove: false,//是否需要审批
            evectionRequired: 0,
            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            curUser: {},
            companyId: '',
            evectionNo: '',
            orderNo: '',
            evectionType: 2,
            evectionInfo: {}, // 出差信息合集
            policyTipsList: [],
            availableTraveler: [],
            availableTravelerIdList: [],
            availableTravelerListForSelector: [],
            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                // travelUserList: [],
                smsType: 1, // 是否发送短信， 1为是， 0为否
                linkmanSms: 1, // 是否给联系人发送短信 1为是， 0为否
                bindAgreementCard: 1, // 是否绑定企业协议卡号 1为是， 0为否
                userList: []
            },
            evectionFormParams: {},

            copyDialog: false,
            copyTextarea: '',
            copyLoading: false,

            priceResult: 0,

            hotelOrderInfo: {
                hotelPicUrl: ''
            },
            form: {
                arrivalTime: '13:00',
                remarks: '',
                linkman: '',
                linkmanPhone: '',
                smsType: 1,
                linkmanSms: 1,
                bindAgreementCard: 1,
                userIdList: [],
                userList: [],
                contact: [],
                roomAmount: 0,
                roomList: [{
                    roomId: 1,
                    user: {}
                }],
                feeAffiliationName: ''
            },
            pickerOptions: {start: '13:00',step: '01:00',end: '24:00'},

            roomAmountList: [],
            curRoomId: 1,
            aUserIdList: [],
            contactObj: {},
            contactIndex: 0,

            // 各组间暂存数据
            temporaryForm: {
                staffIdList: [],
                staffList: [],
                city: null,
                departmentId: "",
                departmentName: "",
                costCenterId: "",
                costCenterName: "",
                accessoryUrl: [],
                staffIdList2: [],
                staffList2: [],
            },
            // 费用归属选择弹窗
            feeAffiliationVisible: false,
            //费用归属部门树配置
            defaultProps: {
                children: "children",
                label: "deptName",
            },
            allocation: {
                staffIdList: "",
                treeData: [],
                treeData2: [],
                treeData3: [],
                rangeDate: null,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    },
                },
                startPickerOptions: {
                    disabledDate: (time) => {
                        const moment = this.$moment;
                        const form = this.form;
                        const startDate = form.startDate;
                        const journeyForm = this.journeyForm;
                        const endDate = form.endDate;
                        if (startDate) {
                            if (journeyForm.returnTime) {
                                return (
                                    time.getTime() < moment(startDate)._d ||
                                    time.getTime() > moment(journeyForm.returnTime)._d
                                );
                            } else {
                                return (
                                    time.getTime() < moment(startDate)._d ||
                                    time.getTime() > moment(endDate)._d
                                );
                            }
                        } else {
                            return time.getTime() < Date.now() - 1000 * 3600 * 24;
                        }
                    },
                },
                endPickerOptions: {
                    disabledDate: (time) => {
                        const moment = this.$moment;
                        const form = this.form;
                        const journeyForm = this.journeyForm;
                        const startDate = form.startDate;
                        const endDate = form.endDate;
                        if (startDate) {
                            if (journeyForm.departTime) {
                                return (
                                    time.getTime() < moment(journeyForm.departTime)._d ||
                                    time.getTime() > moment(endDate)._d
                                );
                            } else {
                                return (
                                    time.getTime() < moment(startDate)._d ||
                                    time.getTime() > moment(endDate)._d
                                );
                            }
                        } else {
                            return time.getTime() < Date.now() - 1000 * 3600 * 24;
                        }
                    },
                },
                defaultStart: null,
                defaultEnd: null,
                departmentList1: null,
                departmentList: [],
                constCenterList: [],
                feeAffiliation: {
                    departmentList: [],
                    constCenterList: [],
                },
                feeAffiliationTypeList: [
                    {name: "1", label: "组织架构"},
                    {name: "2", label: "成本中心"},
                ],
            },
            departmentList1: null,
            departmentList: [],
            constCenterList: [],
            feeAffiliation: {
                departmentList: [],
                constCenterList: [],
            },
            feeAffiliationTypeList: [
                {name: "1", label: "组织架构"},
                {name: "2", label: "成本中心"},
            ],
            checkedKeys: [],
            standards: [{
                policy: '限制价格',
                price: '一线城市0-500元',
                solution: '允许预定'
            }],
            paymentPage: 0, // 是否弹窗 0.不弹 1.弹
            today: '',
            isTodayMorning: false,
            showNotBookDialog: false,
            isDisabledRoomAmount: false,//是否可修改房间数
            isDisabledTraveler: false,//是否可修改入住人

            shareEvectionNo: '',
            shareJourneyId: 0,

            travelFeeAffiliationMsgList: [],

            shareRoomUserList: [],
        }
    },
    components: {
        BackBtn,
        EvectionForm,
        TravelerMultipleSelector,
        BaseMultipleEntitySelector0301,
        ReserveProcessBox
    },
    async activated() {
        this.getPayRemider();
        this.init();
    },
    watch: {
        //房间数量改变时，改变需要选定入住人数和预定总价
        'form.roomAmount'(newVal) {
            if(this.evectionInfo.shareWith !== 1) {
                this.form.roomList = [{
                    roomId: 1,
                    user: {},
                    userIdList: []
                }];
                for (let i = 1; i < newVal; i++) {
                    this.form.roomList.push({
                        roomId: i + 1,
                        user: {},
                        userIdList: []
                    })
                }
                if (this.availableTraveler.length > 0) {
                    for (let i = 0; i < this.form.roomList.length; i++) {
                        if(this.availableTraveler[i] === undefined) {
                            return;
                        }
                        this.form.roomList[i].user = {
                            userId: this.availableTraveler[i].userId,
                            name: this.availableTraveler[i].name,
                            phone: this.availableTraveler[i].phone,
                            certificateName: this.availableTraveler[i].certificateName,
                            certificateValue: this.availableTraveler[i].certificateValue,
                            certificateType: this.availableTraveler[i].certificateType,
                            unSensitiveCertificateValue: this.availableTraveler[i].unSensitiveCertificateValue,
                            unSensitivePhone: this.availableTraveler[i].unSensitivePhone
                        }
                        this.form.roomList[i].userIdList = [this.availableTraveler[i].userId]
                    }
                }
            }

            this.getAllPrice();
        },
        //userIdList变化时，更新userList，并发布userIdList和userList给父组件
        'aUserIdList'(newVal) {

            this.form.roomList.find(room => {
                if (room.roomId === this.curRoomId) {
                    if (newVal.length > 0) {
                        const id = newVal[newVal.length - 1];
                        if (this.evectionDetailType === '001') {
                            this.$refs.aBaseMultipleEntitySelector0301.get_selected_entity_promise({
                                id,
                            }).then( ({entity}) =>{
                                room.user = entity;
                            });
                        } else {
                            this.$refs.aTravelerSingleSelector.get_selected_entity_promise({
                                id,
                            }).then( ({entity}) => {
                                room.user = entity;
                            });
                        }
                        room.userIdList = newVal;
                    }
                }
            });
        },
        //从员工中选择联系人时，
        'form.contact'(newVal) {
            this.contactObj = this.$refs.aContactSingleSelector.get_selected_entity();
            this.form.linkman = this.contactObj.name;
            this.form.linkmanPhone = this.contactObj.phone;
        }
    },
    methods: {
        //初始化
        async init() {
            this.loading = true;
            this.evectionNo = this.$route.query.evectionNo;
            this.companyId = Number(this.$route.query.behalfCompanyId);
            this.orderNo = this.$route.query.orderNo
            this.evectionType = parseInt(this.$route.query.evectionType)
            this.shareEvectionNo = this.$route.query.shareEvectionNo;
            this.shareJourneyId = parseInt(this.$route.query.shareJourneyId || 0);
            this.getEvectionType();
            await this.getStaffsByEvectionNo();
            await this.getEvectionInfo();
            await this.checkFreeApprove();
            await this.getHotelOrderPolicyTips();
            this.initSelectorData();
            await this.getCurUser();
            await this.refundChangePolicyGet();
            if (this.isFreeApprove && this.businessRuleControl === 2 && this.$store.state.workflow === '2') {
                // 流程
                this.$refs['reserveProcessBox'].init().then(res => {
                    this.hasNodeList = res
                })
            }
            this.initEvectionForm();
            this.loading = false;
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.evectionRequired = res.datas.evectionRequired;
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        //初始化动态表单
        initEvectionForm() {
            let userIdList = [];
            if (this.form.roomList && this.form.roomList.length > 0) {
                this.form.roomList.forEach(item => {
                    if (item.user && item.user.userId) {
                        userIdList.push(item.user.userId);
                    }
                })
            }
            this.$forceUpdate();
            this.$refs.evectionForm.init({
                oaNo: this.evectionInfo.partnerEctionId,
                startDate: this.hotelOrderInfo.checkInDate,
                userId: userIdList,
                businessType: 3,
                companyId: this.hotelOrderInfo.companyId,
            });
        },
        //获取出差类型
        getEvectionType() {
            let evectionParams = {
                vm: this
            }
            const evectionQuery = getEvectionAllType(evectionParams)
            this.evectionDetailType = evectionQuery.evectionDetailType;
        },
        // 获取出差信息
        async getEvectionInfo() {
            let [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({
                evectionNo: this.evectionNo,
                pageType: 1,
                personType: 2
            }));
            if (err) {
                return
            }
            this.evectionInfo = res.datas;
            this.travelFeeAffiliationMsgList = this.evectionInfo.travelFeeAffiliationMsgList;
            this.form.travelReason = this.evectionInfo.reason;
            this.form.feeAffiliationName = this.evectionInfo.feeAffiliationName;

            //合住
            if(this.evectionInfo.shareWith == 1) {
                this.form.roomList = [{
                    roomId: 1,
                    user: {},
                    userIdList: []
                },{
                    roomId: 1,
                    user: {},
                    userIdList: []
                }];
                if (this.availableTraveler.length > 0) {
                    let traveler = this.availableTraveler.find(traveler => {
                        return traveler.shareWith === 2;
                    })

                    this.form.roomList[0].user = {
                        userId: traveler.userId,
                        name: traveler.name,
                        phone: traveler.phone,
                        shareWith: traveler.shareWith,
                        certificateName: traveler.certificateName,
                        certificateValue: traveler.certificateValue,
                        certificateType: traveler.certificateType,
                        unSensitiveCertificateValue: traveler.unSensitiveCertificateValue,
                        unSensitivePhone: traveler.unSensitivePhone
                    }
                    this.form.roomList[0].userIdList = [traveler.userId]

                    traveler = this.availableTraveler.find(traveler => {
                        return traveler.shareWith === 1;
                    })

                    this.form.roomList[1].user = {
                        userId: traveler.userId,
                        name: traveler.name,
                        phone: traveler.phone,
                        shareWith: traveler.shareWith,
                        certificateName: traveler.certificateName,
                        certificateValue: traveler.certificateValue,
                        certificateType: traveler.certificateType,
                        unSensitiveCertificateValue: traveler.unSensitiveCertificateValue,
                        unSensitivePhone: traveler.unSensitivePhone
                    }
                    this.form.roomList[1].userIdList = [traveler.userId]
                }
            }

            this.evectionFormParams = {
                reason: this.evectionInfo.reason,
                travelUserList: this.evectionInfo.travellerList,
                feeAffiliationType: this.evectionInfo.feeAffiliationType || 1,
                feeAffiliationName: this.evectionInfo.feeAffiliationName,
                feeAffiliationId: this.evectionInfo.feeAffiliationId,
                partnerEctionId: this.evectionInfo.partnerEctionId || this.evectionInfo.evectionNo,
                centerCode: '',//成本中心新增
                projectCode: '',//归属项目新增Code
                projectName: '',//归属项目新增name
                companyId: 0,//归属项目新增
            }
            this.hotelOrderInfo = this.evectionInfo.todHotelOrderInterimList[0];
            this.orderNo = this.hotelOrderInfo.orderNo

            let roomUserList = [];
            let shareRoomUserList = [];
            this.evectionInfo.travellerList.forEach(value=>{
                if (value.shareWith === 2) {
                    roomUserList.push(value);
                } else {
                    shareRoomUserList.push(value);
                }
            })
            this.shareRoomUserList = shareRoomUserList;

            // 判断入住时间
            this.today = this.$moment().format('YYYY-MM-DD');
            let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
            this.isTodayMorning = this.hotelOrderInfo.checkInDate === yesterday ? true : false;
            if (this.isTodayMorning) {
                //当天凌晨入住
                let hour = 1;
                if (moment().hour() > 1) {
                    hour = moment().hour() + 1;
                }
                this.form.arrivalTime = `${hour}:00`;
                this.pickerOptions = {start: `${hour}:00`,step: '01:00',end: '06:00'}
            } else {
                if (this.hotelOrderInfo.checkInDate === moment().format('YYYY-MM-DD')) {
                    let hour = 13;
                    if (moment().hour() > 13 || (moment().hour() === 13 && moment().minute() > 0)) {
                        hour = moment().hour() + 1;
                    }
                    this.form.arrivalTime = `${hour}:00`;
                    this.pickerOptions = {start: `${hour}:00`,step: '01:00',end: '24:00'}
                } else {
                    this.form.arrivalTime = '13:00';
                    this.pickerOptions = {start: '13:00',step: '01:00',end: '24:00'}
                }
            }

            this.roomAmountList = [];
            for(let i=1; i<this.hotelOrderInfo.maxRoomNum+1; i++){
                this.roomAmountList.push(i);
            }
            this.hotelOrderInfo.newNightlyRateList.forEach(item => {
                let dateText = this.$moment(item.date).format('MM月DD日');
                this.$set(item, 'dateText', dateText);
            })
        },
        evectionChange(val) {
            this.evectionFormParams = val
        },
        //酒店预定页-个人差标提示
        async getHotelOrderPolicyTips() {
          let res = await consumer_t_od_hotel_order_interim_hotelOrderPolicyTips({
              orderNo: this.orderNo
          });
          this.policyTipsList = res.datas;
        },
        async getStaffsByEvectionNo() {
            this.availableTraveler = [];
            this.availableTravelerListForSelector = [];
            let [err, res] = await awaitWrap(consumer_department_getStaffsByEvectionNo({
                evectionNo: this.evectionNo,
                personType: 2,
                pageSize: 999
            }));
            if (err) {
                return
            }
            this.availableTraveler = res.datas.list;
            this.form.roomAmount = 1;
            this.availableTraveler.forEach(item => {
                this.availableTravelerListForSelector.push({
                    userId: item.userId,
                    name: item.name,
                    phone: item.phone,
                    certificateName: item.certificateName,
                    certificateValue: item.certificateValue,
                    certificateType: item.certificateType,
                    unSensitiveCertificateValue: item.unSensitiveCertificateValue,
                    unSensitivePhone: item.unSensitivePhone
                })
            })
        },
        // 获取当前登陆用户信息
        async getCurUser() {
            let res = await get_user_info();
            this.curUser = res.datas;
            this.form.linkman = this.curUser.realName;
            this.form.linkmanPhone = this.curUser.phone;
        },
        //初始化选人弹框数据
        initSelectorData() {
            this.getTravelerMultipleSelectorData();
            this.getContactSingleSelectorData();
        },
        //初始化选择出行人弹框
        getTravelerMultipleSelectorData() {
            if (this.evectionDetailType === '001') {
                const __this = this;
                this.$refs.aBaseMultipleEntitySelector0301.init({
                    dialog: {
                        title: `请选择入住人`,
                    },
                    select_mode: `single`,

                    entity_define: {
                        property_name_for_id: `userId`,
                        property_name_for_name: `name`,
                        get_enable() {
                            return true;
                        },
                    },

                    table: {
                        column_list: [
                            // {
                            //     prop: `userId`,
                            //     label: `id`,
                            //     width: `80`,
                            // },
                            {
                                prop: `name`,
                                label: `姓名`,
                                width: `70`,
                            },
                            {
                                prop: `unSensitivePhone`,
                                label: `联系方式`,
                                width: ``,
                            },
                        ],
                    },

                    get_data_source() {
                        const all_entity_list = __this.availableTravelerListForSelector;
                        return Q.resolve({
                            all_entity_list,
                        });
                    },

                });
            } else {
                this.$refs.aTravelerSingleSelector.init_parameter.get_data_source_parameter = () => {
                    const d = {
                        companyId: this.companyId,
                        isSelectCustomer: true,
                    };
                    return Q.resolve(d);
                };
                this.$refs.aTravelerSingleSelector.init({
                    dialog: {
                        title: `请选择入住人`,
                    },
                    situation: '100',
                    businessType: 3,
                    select_mode: "single"
                })
            }
        },
        //初始化选择联系人弹框
        getContactSingleSelectorData() {
            this.$refs.aContactSingleSelector.init_parameter.get_data_source_parameter = () => {
                const d = {
                    companyId: this.companyId,
                    isSelectCustomer: true,
                };
                return Q.resolve(d);
            };
            this.$refs.aContactSingleSelector.init({
                dialog: {
                    title: `请选择联系人`,
                },
                situation: '101',
                businessType: 3,
                select_mode: "single"
            })
        },
        //选择出行人
        chooseTraveler(room) {
            console.log(room)
            this.curRoomId = room.roomId;
            if (JSON.stringify(room.user) !== '{}' && room.user !== null) {
                this.aUserIdList = [room.user.userId];
            } else {
                this.aUserIdList.splice(0, 1);
            }
            if (this.evectionDetailType === '001') {
                this.$refs.aBaseMultipleEntitySelector0301.show();
            } else {
                this.$refs.aTravelerSingleSelector.show();
            }
        },
        //选择联系人
        chooseContact() {
            this.$refs.aContactSingleSelector.show();
        },
        // 计算订单价格
        async getAllPrice() {
            let params = {
                evectionNo: this.evectionNo,
                headCounts: this.form.roomAmount,
                roomNum: this.form.roomAmount
            }
            let res = await consumer_admin_journey_calculateHotelPrice(params)
            this.priceResult = res.datas
        },
        // 保存信息
        async saveInfo() {
            this.evectionParams = {
                evectionNo: this.evectionNo,
                linkman: this.form.linkman,
                linkmanPhone: this.form.linkmanPhone,
                smsType: this.form.smsType,
                linkmanSms: this.form.linkmanSms,
                bindAgreementCard: this.form.bindAgreementCard,
                userList: this.form.userList,
                ...this.evectionFormParams
            };
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(this.evectionParams))
            if (err) {
                return false
            }
            return res
        },
        //创建订单
        async createOrder() {
            let checkResult = await this.$refs.evectionForm.checkForm();
            let params = {
                evectionNo: this.evectionNo,
                roomNum: this.form.roomAmount,
                latestArrivalTime: this.form.arrivalTime,
                hotelRemark: this.form.remarks,
                orderAmount: this.priceResult.sum,
                reportConfiguration: checkResult.formInfo.list,
                applyJourneyId : this.$route.query.journeysId ? this.$route.query.journeysId : '',
            };
            let [err, result] = await awaitWrap(consumer_t_od_hotel_order_interim_createOrderByOnBusiness(params));
            if (err) {
                return false
            }
            return result;
        },

        //表单检验
        checkForm() {
            let canSubmit = false;

            //必填-到店时间
            if (!this.form.arrivalTime) {
                this.$message({
                    type: 'warning',
                    message: '请选择到店时间'
                })
                return canSubmit;
            }

            //必填-入住人
            let user = {};
            this.form.userList = [];
            if (this.form.roomList && this.form.roomList.length > 0) {
                this.form.roomList.forEach(item => {
                    if (item.user && item.user.userId) {
                        user = {
                            userId: item.user.userId,
                            certificateType: item.user.certificateType
                        };
                        this.form.userList.push(user);
                    }
                })
            }
            if (!this.form.userList || this.form.userList.length !== this.form.roomList.length) {
                this.$message({
                    type: 'warning',
                    message: '请先添加入住人'
                })
                return canSubmit;
            } else {
                let userList = _.uniq(_.pluck(this.form.userList, 'userId'));
                if (userList.length !== this.form.userList.length) {
                    this.$message({
                        type: 'warning',
                        message: '入住人不可选择同一人'
                    })
                    return canSubmit;
                }
            }

            //必填-联系人
            if (this.form.linkman === '' || this.form.linkmanPhone === '') {
                this.$message({
                    type: 'warning',
                    message: '请先填写联系人'
                })
                return canSubmit;
            }

            canSubmit = true;
            return canSubmit;
        },

        //提交
        async submit() {
            if (this.isTodayMorning) {
                let curTimeList = this.$moment().toArray();
                if(curTimeList[3] >= 6) {
                    this.showNotBookDialog = true;
                    return;
                }
            }

            //检查审批流
            if (this.businessRuleControl === 2 && this.orderFreeApprove && !this.hasNodeList) {
                this.$message.warning('当前没有审批流，请联系管理员')
                return
            }

            //表单检验
            let checkResult = await this.$refs.evectionForm.checkForm();
            if (!checkResult.checkResult) {
                this.$message({
                    type: 'warning',
                    message: '请填写因公出行信息'
                });
                this.loading = false
                return
            }
            if (!checkResult.formInfo.checkForm) {
                const listLength = checkResult.formInfo.list.length;
                for (let i = 0; i < listLength; i++) {
                    if (!checkResult.formInfo.list[i].parameterForm) {
                        const text = `请填写${checkResult.formInfo.list[i].parameterText}`;
                        this.$message({
                            type: "warning",
                            message: text
                        });
                        break
                    }
                }
                return
            }
            if (this.checkForm()) {
                //判断是否需要弹提示
                this.toCreateOrder();
            }
        },

        // 最终创建订单
        toCreateOrder() {
            if (this.paymentPage === 1) {
                // 弹窗
                this.$confirm('因公出行订单费用将由企业账户支付', '温馨提示', {
                    confirmButtonText: '继续预订',
                    cancelButtonText: '取消预订',
                    type: 'warning'
                }).then(() => {
                    this.submitOrder();
                }).catch(() => {

                });
            } else {
                // 不弹
                this.submitOrder();
            }
        },

        //检查和提示通过后提交订单
        async submitOrder() {
            let loading
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });

            //保存订单
            let saveResult = await this.saveInfo();
            if (!saveResult) {
                loading.close();
                this.$message({
                    type: 'warning',
                    message: '保存订单信息失败，请重新选择'
                })
                return
            }

            //创建订单
            let createOrderResult = await this.createOrder();
            if (!createOrderResult.datas.isSuccess) {
                loading.close();
                this.$message({
                    type: 'warning',
                    message: '创建订单失败，请重新选择'
                })
                return
            }

            loading.close()

            // businessRuleControl: 因公出差规则 1：出差单审批 2：预订审批
            // overproofStatus: 超标状态：0，未超标；1，超标
            // overprooRule: 酒店管控规则:1.允许预定,只做提示;3.不允许预定 10超标审批11.超额自付 只有超标会有这个字段
            // (出差单审批+已超标+超标审批)或者(预定审批+需要审批) 跳转至 提交成功页
            if ((this.businessRuleControl === 1 && createOrderResult.datas.overproofStatus === 1 && createOrderResult.datas.overprooRule === 10) ||
                (this.businessRuleControl === 2 && this.isFreeApprove)) {
                this.$router.replace({
                    name: 'admin-hotel-reserve-result',
                    query: {
                        orderNo: this.orderNo
                    }
                })
            } else {
                this.$router.replace({
                    name: 'admin-pay',
                    query: {
                        orderNos: this.orderNo,
                        orderType: "3",
                    }
                })
            }
        },

        // 弹出费用归属选择
        popup_feeAffiliation() {
            this.feeAffiliationVisible = true;
        },
        // 费用归属当选择某一部门时
        select_department(data, idSelect) {
            const temporaryForm = this.temporaryForm;
            if (idSelect) {
                const deptId = data.deptId;
                this.allocation.departmentList1.forEach((item, index) => {
                    if (item.deptId === deptId) {
                        this.$set(
                            this.allocation.departmentList1[index],
                            "disabled",
                            false
                        );
                    } else {
                        this.$set(this.allocation.departmentList1[index], "disabled", true);
                    }
                });
                temporaryForm.departmentName = data.deptName;
                temporaryForm.departmentId = data.deptId;
            } else {
                this.addDisable();
                temporaryForm.departmentName = "";
                temporaryForm.departmentId = "";
            }
        },
        //费用归属选择成本中心
        change_costCenter(val) {
            const temporaryForm = this.temporaryForm;
            const constCenterList = this.allocation.constCenterList;
            for (const i in constCenterList) {
                const item = constCenterList[i];
                if (item.deptId === val) temporaryForm.costCenterName = item.deptName;
            }
        },
        // 关闭费用归属选择
        close_feeAffiliation() {
            this.feeAffiliationVisible = false;
        },
        // 提交选择费用归属
        commit_feeAffiliation() {
            this.feeAffiliationVisible = false;
            const form = this.form;
            const feeAffiliationType = Number(form.feeAffiliationType);
            const temporaryForm = this.temporaryForm;
            if (feeAffiliationType === 1) {
                form.feeAffiliationName = temporaryForm.departmentName;
                form.feeAffiliationId = temporaryForm.departmentId;
            } else if (feeAffiliationType === 2) {
                form.feeAffiliationName = temporaryForm.costCenterName;
                form.feeAffiliationId = temporaryForm.costCenterId;
            }
        },
        //是否免审
        async checkFreeApprove() {
            let params = {
                businessType: 3,
                orderNo: this.orderNo
            }
            let res = await consumer_journey_orderFreeApprove(params);
            this.isFreeApprove = res.datas.orderFreeApprove;
        },
        getPayRemider() {
            consumer_payment_payReminder().then(res=>{
                this.paymentPage = res.datas.paymentPage;
            })
        },
        onCloseDialog() {
            this.showNotBookDialog = false;
            this.$router.back();
        },

        //动态表单选择oa单后，禁止修改房间数和入住人
        handleSelectCallback() {
            this.isDisabledRoomAmount = true;//是否可修改房间数
            this.isDisabledTraveler = true;//是否可修改入住人
        },

        //动态表单删除已选的oa单后，可修改房间数和入住人
        handleSelectDelete() {
            this.isDisabledRoomAmount = false;//是否可修改房间数
            this.isDisabledTraveler = false;//是否可修改入住人
        }
    }
}
